import logo from "../../assets/images/logos/comingsoon.svg";
import React from "react";

const ComingSoon = () => {
  return (
    <>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2>Coming Soon!</h2>
        </header>
      </div>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2>Coming Soon!</h2>
        </header>
      </div>
    </>
  );
};

export default ComingSoon;
